<template>
  <v-dialog
    v-model="active"
    width="500"
    :dark="settings.dark || dark"
    @click:outside="close"
  >
    <v-card style="border-radius: 10px">
      <v-card-title class="pt-2 pb-1">
        <v-col cols="12" class="d-flex align-center px-0 pb-0">Settings</v-col>
      </v-card-title>
      <v-card-text class="pt-0 pb-1">
        <v-container>
          <v-row>
            <v-spacer />
            <v-col cols="8" class="d-flex justify-start py-2">
              <v-switch
                v-model="settings.colorless"
                label="Colorless Mode"
                :color="$colors.blue"
                inset
                hide-details
              />
            </v-col>
            <v-spacer />
          </v-row>
          <v-row>
            <v-spacer />
            <v-col cols="8" class="d-flex justify-start py-2">
              <v-switch
                v-model="settings.dark"
                label="Dark Mode"
                :color="$colors.blue"
                inset
                hide-details
              />
            </v-col>
            <v-spacer />
          </v-row>
          <v-row>
            <v-spacer />
            <v-col cols="8" class="d-flex justify-start py-2">
              <v-switch
                v-model="settings.silent"
                label="Silent Mode"
                :color="$colors.blue"
                inset
                hide-details
              />
            </v-col>
            <v-spacer />
          </v-row>
          <v-row v-if="$vuetify.breakpoint.smAndUp">
            <v-spacer />
            <v-col cols="8" class="d-flex justify-start py-2">
              <v-switch
                v-model="settings.hk"
                label="Hide Keyboard"
                :color="$colors.blue"
                inset
                hide-details
              />
            </v-col>
            <v-spacer />
          </v-row>
          <v-row>
            <v-spacer />
            <v-col cols="8" class="d-flex justify-start py-2">
              <v-switch
                v-model="settings.untimed"
                label="Hide Timer"
                :color="$colors.blue"
                inset
                hide-details
              />
            </v-col>
            <v-spacer />
          </v-row>
          <v-row>
            <v-spacer />
            <v-col cols="8" class="d-flex justify-start pt-2">
              <v-switch
                v-model="settings.unwindSpiral"
                label="Unwind Spiral"
                :color="$colors.blue"
                inset
                hide-details
              />
            </v-col>
            <v-spacer />
          </v-row>
        </v-container>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-col cols="12" class="d-flex justify-center">
          <v-btn
            class="close-button"
            :dark="dark"
            block
            text
            @click="$store.commit('USER/SETTINGS_DIALOG_SET', false)"
          >
            Close
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    settings: {
      colorless: false,
      dark: true,
      hk: true,
      silent: false,
      untimed: false,
      unwindSpiral: true,
    },
  }),
  computed: {
    active() {
      return this.$store.state.USER.dialogs.settings;
    },
    dark() {
      return this.$store.state.USER.settings.dark;
    },
    storeSettings() {
      return this.$store.state.USER.settings;
    },
  },
  watch: {
    settings: {
      async handler() {
        if (
          JSON.stringify(this.storeSettings) != JSON.stringify(this.settings) &&
          this.active
        ) {
          await this.$store.dispatch("USER/SETTINGS_SET", this.settings);
        }
      },
      deep: true,
    },
    showSettings() {
      this.$store.commit("USER/SETTINGS_DIALOG_SET", this.showSettings);
    },
    storeSettings: {
      handler() {
        this.settings = { ...this.storeSettings };
      },
      deep: true,
      immediate: true,
    },
  },
  async beforeMount() {
    try {
      await this.applyMissingSettings();
    } catch {
      // localstorage inaccessible, ignore it
    }
  },
  methods: {
    async applyMissingSettings() {
      console.log("Applying missing settings...");
      let lsSettings = await this.$store.dispatch(
        "LOCAL_STORAGE_GET",
        "blockables-settings"
      );
      if (!lsSettings) return;
      lsSettings = JSON.parse(lsSettings);
      // add missing settings
      for (const key in this.settings) {
        if (!(key in lsSettings)) {
          lsSettings[key] = this.settings[key];
        }
      }
      // remove deleted settings
      for (const key in lsSettings) {
        if (!(key in this.settings)) {
          delete lsSettings[key];
        }
      }
      await this.$store.dispatch("LOCAL_STORAGE_SET", {
        id: "blockables-settings",
        value: JSON.stringify(lsSettings),
      });
      await this.$store.dispatch("USER/SETTINGS_SET", lsSettings);
    },
    close() {
      this.$store.commit("USER/SETTINGS_DIALOG_SET", false);
    },
  },
};
</script>

<style></style>
