var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({
    color: _vm.dark ? 'white' : 'black !important',
    'text-align': 'center',
    'text-wrap': 'nowrap',
    'margin-bottom': 0,
    'font-size': '0.875rem',
  })},_vm._l((_vm.unwoundSpiral),function(character,idx){return _c('span',{key:idx,staticClass:"unwoundSpiralLetter",style:({
      'background-color': 
        _vm.selected.c == character.c && _vm.selected.r == character.r ?
          _vm.colorOfSelected :
          'inherit',
      'color':
        _vm.selected.c == character.c && _vm.selected.r == character.r ?
          'black' :
          'inherit',
      'font-weight':
        _vm.selected.c == character.c && _vm.selected.r == character.r ?
          500 :
          'inherit'
    }),attrs:{"id":`character-r${character.r}c${character.c}`}},[_vm._v(_vm._s(character.letter.toUpperCase()))])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }