<template>
  <Puzzle :date="date" />
</template>

<script>
import Puzzle from "@/components/Puzzle.vue";

export default {
  name: "Example",
  components: {
    Puzzle,
  },
  computed: {
    date() {
      return this.$store.state.USER.date;
    },
  },
  async beforeMount() {
    this.$analytics.logEvent("tutorial_begin");
    this.$store.commit("USER/DATE_SET", "03-03-0003");
    this.$store.commit("USER/EXAMPLE_SET", true);
    await this.$store.dispatch("USER/CLEAR_LOCAL_STORAGE");
    setTimeout(() => {
      if ("pendo" in window) {
        if (!("getSerializedMetadata" in window.pendo)) {
          this.$store.commit("USER/INSTRUCTIONS_DIALOG_SET", true);
        }
      } else {
        this.$store.commit("USER/INSTRUCTIONS_DIALOG_SET", true);
      }
    }, 3000);
    try {
      await this.$store.dispatch("PUZZLE/FETCH", {
        ark: this.$ark.active,
        date: this.date,
      });
    } catch (e) {
      this.$store.commit("USER/SNACKBAR_SET", {
        active: true,
        type: "bad",
        text: "003 Blockables was unable to contact the server. Please check your connection or try again later.",
      });
    }
  },
  destroyed() {
    this.$store.commit("USER/EXAMPLE_SET", false);
  },
};
</script>

<style scoped></style>
