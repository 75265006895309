export default {
  async FETCH({ commit }, { date, ark }) {
    commit("RESET");
    commit("ARROW_CLASS_SET", "hidden");
    let resp = null;
    let dc = null;
    if (ark) {
      resp = await this.$fetch(
        `puzzles_arkadium?select=*&date=eq.${date}`,
        "GET"
      );
      dc = resp[0];
    } else {
      resp = await this.$db.from("puzzles").select().eq("date", date);
      dc = resp.data[0];
    }
    commit("DIMENSION_SET", dc.dimension);
    commit("METADATA_SET", { date: date, ...dc });
    commit("PUZZLE_SET", dc);
  },
  async REVEAL_WORD({ dispatch, commit, state }, payload) {
    let clue = { ...payload.clueOfSelected };
    clue.revealed = true;
    commit("INSERT_WORD", {
      clueOfSelected: payload.clueOfSelected,
      clue: clue,
    });
    try {
      await dispatch(
        "LOCAL_STORAGE_SET",
        { id: "blockables-clues", value: JSON.stringify(state.clues) },
        { root: true }
      );
    } catch {
      // localstorage inaccessible, ignore it
    }
  },
};
