import Vue from "vue";
import Vuex from "vuex";

// vuex modules
import CREATOR from "./modules/CREATOR";
import PUZZLE from "./modules/PUZZLE";
import USER from "./modules/USER";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    CREATOR,
    PUZZLE,
    USER,
  },
  state: {
    sb_user: null,
  },
  mutations: {
    USER_CLEAR(state) {
      state.sb_user = null;
    },
    USER_SET: (state, user) => {
      state.sb_user = user;
    },
    // INIT_LOCAL_STORAGE: (state, ls) => {
    //   state.localStorage = ls;
    // },
  },
  actions: {
    async LOCAL_STORAGE_GET(_, id) {
      if (
        process.env.VUE_APP_DEPLOYMENT_PLATFORM == "arkadium-sdk" &&
        process.env.VUE_APP_ENVIRONMENT != "dev"
      ) {
        return await this.$localStorage.getLocalStorageItem(id);
      } else {
        let pr = new Promise(resolve => {
          resolve(this.$localStorage.getItem(id));
        });
        return pr;
      }
    },
    async LOCAL_STORAGE_SET(_, { id, value }) {
      if (
        process.env.VUE_APP_DEPLOYMENT_PLATFORM == "arkadium-sdk" &&
        process.env.VUE_APP_ENVIRONMENT != "dev"
      ) {
        await this.$localStorage.setLocalStorageItem(id, value);
      } else {
        new Promise(resolve => resolve(this.$localStorage.setItem(id, value)));
      }
    },
    async LOCAL_STORAGE_REMOVE(_, id) {
      if (
        process.env.VUE_APP_DEPLOYMENT_PLATFORM == "arkadium-sdk" &&
        process.env.VUE_APP_ENVIRONMENT != "dev"
      ) {
        await this.$localStorage.removeLocalStorageItem(id);
      } else {
        new Promise(resolve => resolve(this.$localStorage.removeItem(id)));
      }
    },
    USER_GET: async ({ commit, dispatch, state }) => {
      if (
        process.env.VUE_APP_DEPLOYMENT_PLATFORM == "arkadium-sdk" ||
        process.env.VUE_APP_DEPLOYMENT_PLATFORM == "arkadium"
      )
        return null;
      try {
        let token = await dispatch(
          "LOCAL_STORAGE_GET",
          "sb-cterwiozjbjdeoazbzdd-auth-token"
        );
        let localStorageUser = JSON.parse(token);
        if (!localStorageUser) return null;
        localStorageUser =
          "user" in localStorageUser ? localStorageUser.user : null;
        let storeUser = state.sb_user;
        commit("USER_SET", storeUser || localStorageUser || null);
      } catch {
        return null;
      }
    },
  },
});

export default store;
