<template>
  <div
    :style="{
      color: dark ? 'white' : 'black !important',
      'text-align': 'center',
      'text-wrap': 'nowrap',
      'margin-bottom': 0,
      'font-size': '0.875rem',
    }"
  >
    <!-- prettier-ignore -->
    <span
      v-for="(character, idx) in unwoundSpiral"
      :id="`character-r${character.r}c${character.c}`"
      :key="idx"
      class="unwoundSpiralLetter"
      :style="{
        'background-color': 
          selected.c == character.c && selected.r == character.r ?
            colorOfSelected :
            'inherit',
        'color':
          selected.c == character.c && selected.r == character.r ?
            'black' :
            'inherit',
        'font-weight':
          selected.c == character.c && selected.r == character.r ?
            500 :
            'inherit'
      }"
    >{{ character.letter.toUpperCase() }}</span>
  </div>
</template>

<script>
export default {
  computed: {
    colorOfSelected() {
      return this.$store.getters["PUZZLE/CLUE_OF_SELECTED"].color;
    },
    dark() {
      return this.$store.state.USER.settings.dark;
    },
    dimension() {
      return this.$store.state.PUZZLE.dimension;
    },
    direction() {
      return this.$store.state.PUZZLE.direction;
    },
    playerGrid() {
      return this.$store.state.PUZZLE.playerGrid;
    },
    selected() {
      return this.$store.state.PUZZLE.selected;
    },
    unwoundSpiral() {
      // if (!this.playerGrid) return "*" * (this.dimesion * this.dimension);
      if (this.windPattern == "hidden") return "";
      let wind = ["right", "down", "left", "up"];
      if (this.windPattern == "counterclockwise") {
        wind = wind.reverse();
      }
      let directionIndex = wind.indexOf(this.direction);
      wind = [...wind.slice(directionIndex), ...wind.slice(0, directionIndex)];

      let unwoundSpiral = [];
      let movements = [1, 1, 2, 2, 3, 3, 4, 4, 5];
      let block = { r: 2, c: 2 };
      if (this.dimension == 3) {
        movements = movements.slice(0, 5);
        block = { r: 1, c: 1 };
      }
      movements.forEach((movement, index) => {
        let currentDirection = wind[index % 4];
        for (let i = 0; i < movement; i++) {
          let letter = this.playerGrid[block.r][block.c]
            ? this.playerGrid[block.r][block.c]
            : "*";
          unwoundSpiral.push({
            ...block,
            letter: letter,
          });
          // if (i != movements.slice(-1)) {
          //   unwoundSpiral += " ";
          // }
          if (currentDirection == "right") {
            block.c += 1;
          }
          if (currentDirection == "down") {
            block.r += 1;
          }
          if (currentDirection == "left") {
            block.c -= 1;
          }
          if (currentDirection == "up") {
            block.r -= 1;
          }
        }
      });

      // return unwoundSpiral.toUpperCase();
      return unwoundSpiral;
    },
    windPattern() {
      return this.$store.state.PUZZLE.arrowClass;
    },
  },
};
</script>

<style scoped>
.unwoundSpiralLetter {
  padding: 0 0.25ch;
}
</style>
