export default {
  async GET_STATS_FROM_DB({ commit, dispatch }, u) {
    let allPuzzleAccess = await this.$db.from("user_mgmt").select();
    if (!allPuzzleAccess.error) {
      commit("SET_ALL_PUZZLES", true);
    }
    let recordToSave = null;
    try {
      recordToSave = await dispatch("LOCAL_STORAGE_GET", "blockables-record", {
        root: true,
      });
    } catch {
      recordToSave = null;
    }
    if (recordToSave) {
      let partialRecord = JSON.parse(recordToSave);
      const { error } = await this.$db
        .from("stats")
        .insert({ ...partialRecord, user_email: u.email });
      if (error && error.code == "23505") {
        await dispatch("LOCAL_STORAGE_REMOVE", "blockables-record", {
          root: true,
        });
      }
    }
    const { data, error } = await this.$db.rpc("get_stats_order_by_ordinal", {
      user_email: u.email,
    });
    if (!error) {
      commit("STATS_SET", [...data]);
    }

    let resp = await this.$db.rpc("get_past_seven_puzzles", {
      user_email: u.email,
    });
    let gpspd = resp.data;
    let gpspe = resp.error;
    if (!gpspe) {
      commit("MISSED_PUZZLES_SET", [...gpspd]);
    }
  },
  async SIGN_OUT({ commit, dispatch }) {
    const { error } = await this.$db.auth.signOut();
    if (!error) {
      await dispatch("ELIMINATE_LOCAL_STORAGE");
      commit("SET_ALL_PUZZLES", true);
      commit("USER_CLEAR", null, { root: true });
    }
  },
  // Player
  async CLEAR_LOCAL_STORAGE({ dispatch }) {
    try {
      let removals = [
        "blockables-assists",
        "blockables-clues",
        "blockables-grid",
        "blockables-puzzle-revealed",
        "blockables-record",
        "blockables-scratches",
        "blockables-time",
        "blockables-timed",
      ];
      removals.forEach(async r => {
        await dispatch("LOCAL_STORAGE_REMOVE", r, {
          root: true,
        });
      });
    } catch {
      // localstorage inaccessible, ignore it
    }
  },
  async ELIMINATE_LOCAL_STORAGE({ dispatch }) {
    try {
      let removals = [
        "blockables-assists",
        "blockables-clues",
        "blockables-grid",
        "blockables-puzzle-revealed",
        "blockables-record",
        "blockables-scratches",
        "blockables-time",
        "blockables-timed",
        "blockables-won-today",
      ];
      removals.forEach(async r => {
        await dispatch("LOCAL_STORAGE_REMOVE", r, {
          root: true,
        });
      });
    } catch {
      // localstorage inaccessible, ignore it
    }
  },
  FAST_WIN: async ({ dispatch, state }) => {
    let lsbpr = true;
    try {
      lsbpr = await dispatch(
        "LOCAL_STORAGE_GET",
        "blockables-puzzle-revealed",
        {
          root: true,
        }
      );
    } catch {
      return false;
    }
    let doesntQualify = Boolean(lsbpr);
    return (
      parseInt(state.timer) < 200 &&
      !state.settings.untimed &&
      !state.confirmedPuzzleReveal &&
      !doesntQualify
    );
  },
  SETTINGS_SET: async ({ dispatch, state }, settings) => {
    try {
      await dispatch(
        "LOCAL_STORAGE_SET",
        { id: "blockables-settings", value: JSON.stringify(settings) },
        { root: true }
      );
    } catch {
      // localstorage inaccessible, ignore it
    }
    state.settings = { ...settings };
    let counter = 0;
    let pendoInterval = setInterval(() => {
      if ("pendo" in window && "getSerializedMetadata" in window.pendo) {
        const visitor = window.pendo.getSerializedMetadata().visitor;
        window.pendo.updateOptions({
          visitor: {
            ...visitor,
            ...settings,
          },
        });
        clearInterval(pendoInterval);
      }
      if (counter > 20) {
        clearInterval(pendoInterval);
      }
      counter++;
    }, 250);
  },
  INCREMENT_ASSISTS_COUNTER: async ({ state, dispatch }, piece) => {
    let type = piece.includes("reveal") ? "reveals" : "checks";
    state.assists[type] += 1;
    state.assists.piece = piece;
    state.assists.crumbs.push(piece);
    try {
      await dispatch(
        "LOCAL_STORAGE_SET",
        { id: "blockables-assists", value: JSON.stringify(state.assists) },
        { root: true }
      );
    } catch {
      // localstorage inaccessible, ignore it
    }
  },
  COORDS_REVEAL_APPEND: async ({ state, dispatch }, coord) => {
    let cString = `${coord.r}-${coord.c}`;
    if (state.assists.coordsRevealed.includes(cString)) return;
    state.assists.coordsRevealed.push(cString);
    try {
      await dispatch(
        "LOCAL_STORAGE_SET",
        { id: "blockables-assists", value: JSON.stringify(state.assists) },
        { root: true }
      );
    } catch {
      // localstorage inaccessible, ignore it
    }
  },
  PUZZLE_CONFIRM_REVEAL: async ({ state, dispatch }, active) => {
    state.confirmedPuzzleReveal = active;
    if (!state.example) {
      try {
        await dispatch(
          "LOCAL_STORAGE_SET",
          {
            id: "blockables-puzzle-revealed",
            value: JSON.stringify(state.assists),
          },
          { root: true }
        );
      } catch {
        // localstorage inaccessible, ignore it
      }
    }
    state.dialogs.revealPuzzle = false;
    state.assists.reveals = 25;
  },
  REINITIALIZE: async ({ dispatch, state }) => {
    try {
      let assists = await dispatch("LOCAL_STORAGE_GET", "blockables-assists", {
        root: true,
      });
      if (assists) {
        state.assists = JSON.parse(assists);
      } else {
        state.assists = {
          checks: 0,
          coordsRevealed: [],
          crumbs: [],
          piece: "letter",
          reveals: 0,
        };
      }
      state.confirmedPuzzleReveal = await dispatch(
        "LOCAL_STORAGE_GET",
        "blockables-puzzle-revealed",
        { root: true }
      );
    } catch (e) {
      state.assists = {
        checks: 0,
        coordsRevealed: [],
        crumbs: [],
        piece: "letter",
        reveals: 0,
      };
    }
  },
  TIMER_START: async ({ dispatch, state }) => {
    clearInterval(state.timerInterval);
    try {
      // eslint-disable-next-line no-unused-vars
      let testLocal = await dispatch("LOCAL_STORAGE_GET", "blockables-time", {
        root: true,
      });
      state.timerInterval = setInterval(async () => {
        state.timer++;
        await dispatch(
          "LOCAL_STORAGE_SET",
          { id: "blockables-time", value: state.timer },
          {
            root: true,
          }
        );
        await dispatch(
          "LOCAL_STORAGE_SET",
          { id: "blockables-timed", value: !state.settings.untimed },
          {
            root: true,
          }
        );
      }, 1000);
    } catch {
      state.timerInterval = setInterval(() => {
        state.timer++;
      }, 1000);
    }
  },
};
