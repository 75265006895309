<template>
  <v-dialog :dark="dark" :value="active" width="500" persistent>
    <v-card style="overflow: hidden">
      <v-row>
        <v-spacer />
        <v-col cols="10" class="justify-center text-center">
          <v-icon
            class="win-close-icon"
            @click="$store.commit('USER/WIN_DIALOG_SET', false)"
          >
            {{ icons.close }}
          </v-icon>
          <h1 v-if="fastWin" style="font-size: 100px" class="fast-swing">
            {{ speedEmoji }}
          </h1>
          <h1 v-else style="font-size: 100px" class="swing">🎉</h1>
          <br />
          <p
            v-if="timed && speedEmoji == '👑'"
            style="font-size: 24px; margin-bottom: 20px"
          >
            Behold, you are
            <br />
            <strong>Blockables Royalty!</strong>
          </p>
          <p v-if="timed && speedEmoji == '👑'">
            You bested this measly puzzle in a mere
            <strong>{{ timeInSeconds }} seconds!</strong>
          </p>
          <p
            v-if="timed && speedEmoji == '🎷'"
            style="font-size: 24px; margin-bottom: 20px"
          >
            Dang, you are
            <strong>fast!</strong>
            <br />
            You solved Blockable #{{ puzzleNumber }} in
            <strong>{{ timeInHumanWords }}!</strong>
          </p>
          <p
            v-if="timed && !fastWin && !example"
            style="font-size: 24px; margin-bottom: 20px"
          >
            You solved Blockable #{{ puzzleNumber }} in
            <strong>{{ timeInHumanWords }}.</strong>
          </p>
          <p v-if="example" style="font-size: 24px; margin-bottom: 20px">
            You solved the example Blockable!
          </p>
          <p
            v-if="!timed && !example"
            style="font-size: 24px; margin-bottom: 20px"
          >
            You solved Blockable #{{ puzzleNumber }}!
          </p>
          <template v-if="!example">
            <hr />
            <v-row>
              <v-spacer />
              <v-col cols="12" class="d-flex justify-center">
                <v-list v-if="user">
                  <v-list-item one-line>
                    <v-list-item-content>
                      <v-list-item-title>
                        <strong>Your score has been saved!</strong>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <SignInMenu v-else activator="list" />
              </v-col>
              <v-spacer />
            </v-row>
            <hr />
          </template>
          <p v-if="!example" class="pt-5">
            Blockable #{{ puzzleNumber }} Answer:
            <br />
            <strong>{{ phrase }}</strong>
          </p>
          <p v-else>
            Example Blockable Answer:
            <br />
            <strong>{{ phrase }}</strong>
          </p>
          <template v-if="!example">
            <p>
              Tomorrow's Blockable in:
              <b>{{ timeRemaining }}...</b>
            </p>
            <!-- <BookRaffle v-if="!allPuzzles" :tadas="false" /> -->
            <PlayPastPuzzlesToggle />
            <v-btn
              block
              :color="$colors.green"
              class="white--text"
              @click="copyText"
            >
              Share
              <v-icon class="pl-2">
                {{ icons[clipboardIcon] }}
              </v-icon>
            </v-btn>
          </template>
          <template v-else>
            <p>Ready to try the real thing?</p>
            <v-btn
              block
              :color="$colors.green"
              class="white--text"
              @click="navToHome"
            >
              Play Today's Blockable
            </v-btn>
          </template>
          <p />
        </v-col>
        <v-spacer />
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClipboardOutline, mdiClipboardCheck, mdiClose } from "@mdi/js";

import PlayPastPuzzlesToggle from "../buttons/PlayPastPuzzlesToggle.vue";
import SignInMenu from "@/components/SignInMenu.vue";

export default {
  components: { SignInMenu, PlayPastPuzzlesToggle },
  data: () => ({
    clipboardIcon: "outline",
    fastWin: false,
    icons: {
      check: mdiClipboardCheck,
      close: mdiClose,
      outline: mdiClipboardOutline,
    },
    pppActive: false,
    scoreSaved: false,
    tomorrow: null,
  }),
  computed: {
    active() {
      return this.$store.state.USER.dialogs.win;
    },
    allPuzzles() {
      return true;
    },
    checks() {
      return this.$store.state.USER.assists.checks;
    },
    coordsRevealed() {
      return this.$store.state.USER.assists.coordsRevealed.length;
    },
    dark() {
      return this.$store.state.USER.settings.dark;
    },
    example() {
      return this.$route.name == "How to Play";
    },
    helperEmojis() {
      return this.$store.getters["USER/HELPER_EMOJIS"];
    },
    phrase() {
      return this.$store.state.PUZZLE.phrase;
    },
    puzzleDate() {
      return this.$store.state.PUZZLE.date;
    },
    puzzleId() {
      return this.$store.state.PUZZLE.metadata.puzzleId;
    },
    puzzleNumber() {
      return this.$store.state.PUZZLE.metadata.puzzleNumber;
    },
    record() {
      let textMap = {
        "👑": "crown",
        "🎷": "sax",
        "🎉": "tada",
      };
      return {
        checks: this.checks,
        letters_revealed: this.coordsRevealed,
        puzzle_id: this.puzzleId,
        puzzle_ordinal: this.puzzleNumber,
        symbol: textMap[this.speedEmoji],
        timed: this.timed,
      };
    },
    silent() {
      return this.$store.state.USER.settings.silent;
    },
    speedEmoji() {
      return this.$store.getters["USER/GET_SPEED_EMOJI"];
    },
    timed() {
      return !this.$store.state.USER.settings.untimed;
    },
    timer() {
      return this.$store.getters["USER/GET_TIME"](null);
    },
    timeInHumanWords() {
      let solve = this.timer;
      let split = this.timer.split(":");
      let minutes = parseInt(split[0]);
      let seconds = parseInt(split[1]);
      if (minutes == "0") {
        solve = seconds + " seconds";
      }
      return solve;
    },
    timeRemaining() {
      return this.$store.getters["USER/TIME_REMAINING"];
    },
    timeInSeconds() {
      return this.$store.state.USER.timer;
    },
    today() {
      return this.$store.getters["USER/TODAY"](this.$ark.active);
    },
    user() {
      return !this.$ark.active && this.$store.state.sb_user;
    },
  },
  watch: {
    active: {
      async handler() {
        if (this.active) {
          let time = null;
          try {
            time =
              this.timeInSeconds ||
              (await this.$store.dispatch(
                "LOCAL_STORAGE_GET",
                "blockables-time"
              ));
          } catch {
            time = this.timeInSeconds;
          }
          this.$store.commit("USER/PLAYING_SET", false);
          this.$store.commit("USER/TIMER_STOP");
          this.$store.commit("USER/MOBILE_KEYPRESS_SET", null); // reset keys
          this.fastWin = await this.$store.dispatch("USER/FAST_WIN");
          this.$store.commit("USER/COUNTDOWN_START");
          await this.logWin();
          try {
            let wonToday = await this.$store.dispatch(
              "LOCAL_STORAGE_GET",
              "blockables-won-today"
            );
            if (!wonToday) {
              let completedToday = false;
              this.$analytics.logEvent("puzzle_complete", {
                checks: this.checks,
                letters_revealed: this.coordsRevealed,
                time: this.timeInSeconds,
              });
              if (this.user) {
                const { error } = await this.$db.from("stats").insert({
                  ...this.record,
                  time: time,
                  user_email: this.user.email,
                });
                if (error) {
                  if (error.code == "23505") {
                    // puzzle completed today and they cleared localstorage
                    completedToday = true;
                    this.scoreSaved = true;
                  }
                } else {
                  this.scoreSaved = true;
                }
                await this.$store.dispatch("USER/GET_STATS_FROM_DB", this.user);
              }
              let totalRecord = {
                time: time,
                ...this.record,
              };
              await this.$store.dispatch("LOCAL_STORAGE_SET", {
                id: "blockables-record",
                value: JSON.stringify(totalRecord),
              });
              if (!this.silent && !completedToday) {
                this.$store.state.USER.audio.volume = 0.4;
                let celebrate = require("@/assets/sounds/tada.mp3");
                if (this.record.symbol == "crown") {
                  celebrate = require("@/assets/sounds/crown.mp3");
                } else if (this.record.symbol == "sax") {
                  celebrate = require("@/assets/sounds/sax.mp3");
                }
                this.$store.state.USER.audio.src = celebrate;
                this.$store.state.USER.audio.play();
              }
              if (!this.example) {
                await this.$store.dispatch("LOCAL_STORAGE_SET", {
                  id: "blockables-won-today",
                  value: 1,
                });
              }
            } else {
              this.clipboardIcon = "outline";
            }
          } catch (e) {
            this.$analytics.logEvent("puzzle_complete", {
              checks: this.checks,
              letters_revealed: this.coordsRevealed,
              time: this.timeInSeconds,
            });
            let completedToday = false;
            if (this.user) {
              const { error } = await this.$db.from("stats").insert({
                ...this.record,
                time: time,
                user_email: this.user.email,
              });
              if (error) {
                if (error.code == "23505") {
                  // puzzle completed today and they cleared localstorage
                  completedToday = true;
                  this.scoreSaved = true;
                }
              } else {
                this.scoreSaved = true;
              }
              await this.$store.dispatch("USER/GET_STATS_FROM_DB", this.user);
            }
            if (!this.silent && !completedToday) {
              this.$store.state.USER.audio.volume = 0.4;
              let celebrate = require("@/assets/sounds/tada.mp3");
              if (this.record.symbol == "crown") {
                celebrate = require("@/assets/sounds/crown.mp3");
              } else if (this.record.symbol == "sax") {
                celebrate = require("@/assets/sounds/sax.mp3");
              }
              this.$store.state.USER.audio.src = celebrate;
              this.$store.state.USER.audio.play();
            }
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    copyText() {
      let todaysPuzzle = !("d" in this.$route.params);
      let firstWords = todaysPuzzle ? "Today's puzzle" : "Puzzle";
      if (!this.timed) {
        navigator.clipboard.writeText(
          `${firstWords} completed! ${this.speedEmoji}\nBlockable #${this.puzzleNumber} — ${this.helperEmojis}\nhttps://www.blockables.app`
        );
      } else {
        navigator.clipboard.writeText(
          `${firstWords} completed in ${this.timeInHumanWords}! ${this.speedEmoji}\nBlockable #${this.puzzleNumber} — ${this.helperEmojis}\nhttps://www.blockables.app`
        );
      }
      this.clipboardIcon = "check";
      this.$store.commit("USER/SNACKBAR_SET", {
        active: true,
        text: "Results copied to clipboard!",
        type: "success",
      });
      setTimeout(() => {
        this.$store.commit("USER/SNACKBAR_SET", {
          active: false,
          text: "",
          type: "success",
        });
      }, 3000);
    },
    async logWin() {
      if (!this.user) {
        try {
          let wins = await this.$store.dispatch(
            "LOCAL_STORAGE_GET",
            "blockables-ark-wins"
          );
          if (!wins) {
            wins = [this.puzzleNumber];
          } else {
            wins = JSON.parse(wins);
            if (!wins.length) {
              wins = [this.puzzleNumber];
            } else {
              wins.push(this.puzzleNumber);
            }
          }
          await this.$store.dispatch("LOCAL_STORAGE_SET", {
            id: "blockables-ark-wins",
            value: JSON.stringify(wins),
          });
          await this.$store.dispatch("USER/ELIMINATE_LOCAL_STORAGE");
        } catch {
          // localstorage inaccessible, ignore it
        }
      }
    },
    async navToHome() {
      await this.$store.dispatch("USER/REINITIALIZE");
      this.$store.commit("USER/INCREMENT_PLAY_AGAIN_COUNTER");
      this.$store.commit("USER/WIN_DIALOG_SET", false);
      this.$store.commit("USER/COUNTDOWN_START");
      this.$store.commit("USER/EXAMPLE_SET", false);
      this.$store.commit("USER/GAME_START", false);
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.swing {
  margin: 10px 0 -10px 0;
  animation: swing ease-in-out 1s infinite alternate;
}

.win-close-icon {
  position: absolute;
  top: 25px;
  right: 40px;
  z-index: 500;
}

@keyframes swing {
  0% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}

.fast-swing {
  animation: swing ease-in-out 0.5s infinite alternate;
}

@keyframes swing {
  0% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(-15deg);
  }
}
</style>
