import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import { db, sbfetch } from "./supabaseDb.js";
import { api } from "./arkadium.js";

Vue.config.productionTip = false;

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
};

firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics();

Vue.prototype.$analytics = analytics;

if (process.env.VUE_APP_DEPLOYMENT_PLATFORM == "blockables.app") {
  Vue.prototype.$db = db;
  store.$db = db;

  Vue.prototype.$colors = {
    blue: "#7C93CB",
    red: "#EE7B8C",
    green: "#A9D594",
    purple: "#B86BAC",
    pink: "#E6B0D1",
    orange: "#F5C146",
    yellow: "#F3F1A0",
    "light-blue": "#A1DBE3",
    "default-text": "#000",
  };

  Vue.prototype.$ark = { active: false };

  store.$localStorage = localStorage;

  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
  }).$mount("#app");
}

if (process.env.VUE_APP_DEPLOYMENT_PLATFORM == "arkadium") {
  require("../public/arena-api-integration-blockables/arena-api-1.2.3.js");
  require("../public/arena-api-integration-blockables/ArenaHelper.js");

  const ArenaHelper = require("../public/arena-api-integration-blockables/ArenaHelper.js");
  Vue.prototype.$ark = { active: true, api: ArenaHelper.getInstance() };

  Vue.prototype.$db = null;

  Vue.prototype.$fetch = sbfetch;
  store.$fetch = Vue.prototype.$fetch;

  Vue.prototype.$colors = {
    blue: "#5897A9",
    red: "#DE7567",
    green: "#838D5D",
    purple: "#C4B461",
    pink: "#F0D7DA",
    orange: "#EB8D50",
    yellow: "#EFC237",
    "light-blue": "#C6DCE9",
    "default-text": "#FFF",
  };

  store.$localStorage = localStorage;

  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
  }).$mount("#app");
}

if (process.env.VUE_APP_DEPLOYMENT_PLATFORM == "arkadium-sdk") {
  document.addEventListener("DOMContentLoaded", async () => {
    const arkAPI = new api(true, process.env.VUE_APP_ENVIRONMENT == "dev");
    await arkAPI.initSDK();
    Vue.prototype.$ark = {
      active: true,
      api: arkAPI,
    };
    Vue.prototype.$db = null;

    Vue.prototype.$fetch = sbfetch;
    store.$fetch = Vue.prototype.$fetch;

    Vue.prototype.$colors = {
      blue: "#5897A9",
      red: "#DE7567",
      green: "#838D5D",
      purple: "#C4B461",
      pink: "#F0D7DA",
      orange: "#EB8D50",
      yellow: "#EFC237",
      "light-blue": "#C6DCE9",
      "default-text": "#FFF",
    };

    await Vue.prototype.$ark.api.callAsyncSDKFunction("onTestReady"); //called when game is ready to be shown
    Vue.prototype.$ark.api.initPersistence();

    store.$localStorage = Vue.prototype.$ark.api;

    new Vue({
      router,
      store,
      vuetify,
      render: h => h(App),
    }).$mount("#app");
  });
}
