export default {
  INSERT_LETTER_INTO_PLAYER_GRID(state, payload) {
    let playerRow = state.playerGrid[payload.r];
    playerRow[payload.c] = payload.letter;
    state.playerGrid.splice(payload.r, 1, playerRow);
  },
  POPULATE_CHECK(state, payload) {
    let check = payload.check ? "X" : "";
    let r = payload.r !== null ? payload.r : state.selected.r;
    let c = payload.c !== null ? payload.c : state.selected.c;
    let checkRow = state.checks[r];
    checkRow[c] = check;
    state.checks.splice(r, 1, checkRow);
  },
  RESET(state) {
    state.arrowClass = "hidden";
    state.assignedFocus = "grid";
    state.checks = [
      ...Array.from(Array(state.dimension), () =>
        Array(state.dimension).fill(null)
      ),
    ];
    state.clues = null;
    state.direction = null;
    state.gridColors = null;
    state.numberPlacements = null;
    state.phrase = "";
    state.playerGrid = [
      ...Array.from(Array(state.dimension), () =>
        Array(state.dimension).fill(null)
      ),
    ];
    state.scratches = { ...{} };
    state.selected = {
      ...{
        r: 0,
        c: 0,
      },
    };
    state.winGrid = null;
  },
  INSERT_WORD(state, payload) {
    state.clues.splice(payload.clueOfSelected.ordinal - 1, 1, payload.clue);
  },
  SELECT(state, payload) {
    state.assignedFocus = "grid";
    state.selected = { ...payload };
    document.getElementById(`r${payload.r}c${payload.c}`).focus();
  },
};
