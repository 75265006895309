export default {
  INCREMENT_EXAMPLE_STEPPER(state) {
    state.exampleStepper++;
  },
  RESET_EXAMPLE_STEPPER(state) {
    state.exampleStepper = 0;
  },
  COUNTDOWN_START(state) {
    state.countdownInterval = setInterval(() => {
      state.countdown++;
    }, 1000);
  },
  GAME_START(state, gs) {
    state.gameStarted = gs;
    state.playing = gs;
  },
  INCREMENT_PLAY_AGAIN_COUNTER(state) {
    if (!state.example) {
      state.playAgainCount += 1;
      state.playing = true;
    }
  },
  PLAY_PAST_PUZZLES_SET(state, set) {
    state.playPastPuzzles = set;
  },
  SET_ALL_PUZZLES(state, ap) {
    state.allPuzzles = ap;
  },
  TIMER_STOP(state) {
    clearInterval(state.timerInterval);
  },
};
