<template>
  <v-row>
    <v-spacer />
    <v-col
      cols="10"
      sm="12"
      lg="10"
      xl="8"
      class="d-flex align-center justify-end py-1"
      :tutorial="example ? `example-cell-${exampleStepper}-helpers` : false"
    >
      <v-menu :dark="dark" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="helper-button px-1"
            text
            :dark="dark"
            v-bind="attrs"
            v-on="on"
            tabindex="-1"
          >
            Check
            <v-icon class="pl-0">{{ icons.chevronDown }}</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item id="check-letter" @click="assist('check-letter')">
            <v-list-item-title>Letter</v-list-item-title>
          </v-list-item>
          <v-list-item
            id="check-word-in-grid"
            @click="assist('check-word-in-grid')"
          >
            <v-list-item-title>Word in Grid</v-list-item-title>
          </v-list-item>
          <v-list-item id="check-puzzle" @click="assist('check-puzzle')">
            <v-list-item-title>Puzzle</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu :dark="dark" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="helper-button px-1"
            text
            :dark="dark"
            v-bind="attrs"
            v-on="on"
            tabindex="-1"
          >
            Reveal
            <v-icon class="pl-0">{{ icons.chevronDown }}</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item id="reveal-letter" @click="assist('reveal-letter')">
            <v-list-item-title>Letter</v-list-item-title>
          </v-list-item>
          <v-list-item id="reveal-word" @click="assist('reveal-word')">
            <v-list-item-title>Word</v-list-item-title>
          </v-list-item>
          <v-list-item
            id="reveal-word-in-grid"
            @click="assist('reveal-word-in-grid')"
          >
            <v-list-item-title>Word in Grid</v-list-item-title>
          </v-list-item>
          <v-list-item id="reveal-puzzle" @click="assist('reveal-puzzle')">
            <v-list-item-title>Puzzle</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
    <v-spacer />
  </v-row>
</template>

<script>
import { mdiChevronDown } from "@mdi/js";

export default {
  data: () => ({
    icons: {
      chevronDown: mdiChevronDown,
    },
  }),
  computed: {
    dark() {
      return this.$store.state.USER.settings.dark;
    },
    example() {
      return this.$route.name == "How to Play";
    },
    exampleStepper() {
      return this.$store.state.USER.exampleStepper;
    },
  },
  methods: {
    async assist(piece) {
      if (
        this.$ark.active &&
        process.env.VUE_APP_DEPLOYMENT_PLATFORM == "arkadium"
      ) {
        this.$ark.api.handleEventChange();
      }
      await this.$store.dispatch("USER/INCREMENT_ASSISTS_COUNTER", piece);
    },
  },
};
</script>

<style>
@media (max-width: 599px) {
  .helper-button {
    height: 24px !important;
  }
}
</style>
